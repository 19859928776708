import React, {type FC} from 'react';
import {Helmet} from 'react-helmet';
import {AppText} from "src/shared/ui/AppText";
import {Logo} from "src/widgets/Logo";
import {AppTextTag} from "src/shared/ui/AppText/ui/AppText";
import cls from './NotFoundPage.module.scss';

export const NotFoundPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>{''}</title>
        <meta name="description" content={''} />
        <meta name="keywords" content={''} />
      </Helmet>

      <section className={cls.page}>
        <AppText
          text={'404'}
          fontWeight={700}
          fontSize={36}
          lineHeight={36}
          tag={AppTextTag.H1}
        />
        <AppText
          text={'Page not found'}
          fontWeight={400}
          fontSize={36}
          lineHeight={36}
          tag={AppTextTag.H1}
          align={"center"}
        />
        <Logo height={'100px'} />
      </section>
    </>
  );
};
