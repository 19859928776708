import { classNames } from 'src/shared/lib/classNames/classNames';
import cls from './AppText.module.scss';
import { type ButtonHTMLAttributes, type FC } from 'react';
import { TAlignText, TTagText } from 'src/shared/ui/AppText/ui/helper';

export enum AppTextTheme {
  WHITE = 'white',
  BLACK = 'black',
  GREY = 'grey',
  BLUE = 'blue',
}

export enum AppTextTag {
  P = 'p',
  SPAN = 'span',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
}

interface AppTextProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  theme?: string;
  text: string;
  fontWeight?: number;
  fontSize?: number;
  lineHeight?: number;
  tag?: AppTextTag;
  align?: TAlignText;
  onClick?: () => void;
}

export const AppText: FC<AppTextProps> = (props) => {
  const {
    className = '',
    theme = AppTextTheme.WHITE,
    text,
    fontWeight = 400,
    fontSize = 14,
    lineHeight = 16,
    tag = AppTextTag.P,
    align = 'left',
    onClick,
  } = props;

  const CustomTag = tag;

  const textStyle = () => {
    return {
      fontSize: `${fontSize}px`,
      lineHeight: `${lineHeight}px`,
      fontWeight: fontWeight,
      textAlign: align,
    };
  };

  return (
    <CustomTag
      style={textStyle()}
      className={classNames(cls.appText, {}, [cls[theme], className])}
      onClick={onClick}
    >
      {text}
    </CustomTag>
  );
};
