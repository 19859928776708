import {classNames} from "src/shared/lib/classNames/classNames";
import cls from './Logo.module.scss'
import React, { type FC } from 'react'
import LogoImage from "src/shared/assets/img/logo.svg";
import {Link} from "react-router-dom";

interface LogoProps {
  className?: string;
  height?: string;
}

export const Logo: FC<LogoProps> = (props: LogoProps) => {
  const { className = '', height = '' } = props;

  return (
    <Link to={'/'} className={classNames(cls.logo, {}, [className])}>
      <img src={LogoImage} alt={'logo'} style={{height: height}} />
    </Link>
  );
};