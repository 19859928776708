import React from 'react';
import {AppRouter} from "src/app/providers/router/AppRouter";
import 'src/shared/assets/fonts/Jost/Jost.scss'

export function App() {
  return (
    <>
      <AppRouter/>
    </>
  );
}

