import React from 'react';
import cls from './Apps.module.scss'
import {classNames} from "src/shared/lib/classNames/classNames";
import {AppText} from "src/shared/ui/AppText";
import {AppTextTheme} from "src/shared/ui/AppText/ui/AppText";
import AppleImage from 'src/shared/assets/img/apps/img/apple.png'
import GooglePlay from 'src/shared/assets/img/apps/img/googleplay.png'
import HuaweiImage from 'src/shared/assets/img/apps/img/huawei.png'
import ApkImage from 'src/shared/assets/img/apps/img/apk.svg'

interface AppsProps {
    className?: string
}

export const Apps = (props: AppsProps) => {
    const { className = '' } = props

    const appsList = [
        {
            icon: AppleImage,
            title: 'AppStore',
            link: ''
        },
        {
            icon: GooglePlay,
            title: 'Google Play',
            link: ''
        },
        {
            icon: HuaweiImage,
            title: 'AppGallery',
            link: ''
        },
        {
            icon: ApkImage,
            title: 'APK',
            link: ''
        }
    ]

    return (
        <div className={classNames(cls.apps, {}, [className])}>
            {
                appsList.map((item, index) => (
                    // <Link to={item.link} key={index} className={cls.appsItem}>
                    //     <img src={item.icon} alt="Icon"/>
                    //     <AppText
                    //         text={item.title}
                    //         fontSize={18}
                    //         fontWeight={600}
                    //         theme={AppTextTheme.BLACK}
                    //     />
                    // </Link>
                    <div key={index} className={cls.appsItem}>
                       <img src={item.icon} alt="Icon"/>
                        <AppText
                        text={item.title}
                        fontSize={18}
                        fontWeight={600}
                        theme={AppTextTheme.BLACK}
                        />
                    </div>
                ))
            }

        </div>
    );
};

