import {AppRoutes, getRouteMain, getRouteNotFound, getRouteSupport, getRouteTermsOfUse} from "src/shared/config/router";
import {AppRoutesProps} from "src/shared/types/router";
import {MainPage} from "src/pages/MainPage/MainPage";
import {TermsPrivacyPage} from "src/pages/TemsPrivacyPage/TermsPrivacyPage";
import {SupportPage} from "src/pages/SupportPage/SupportPage";
import React from "react";
import {NotFoundPage} from "src/pages/NotFoundPage/NotFoundPage";

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPage />,
  },
  [AppRoutes.TERMSOFUSE]: {
    path: getRouteTermsOfUse(),
    element: <TermsPrivacyPage />,
  },
  [AppRoutes.SUPPORT]: {
    path: getRouteSupport(),
    element: <SupportPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: getRouteNotFound(),
    element: <NotFoundPage />,
  },
};
