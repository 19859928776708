import React from 'react';
import ReactDOM, {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {App} from "src/app/App";
import '../src/shared/assets/styles/global.scss'

const container = document.getElementById('root');

if (!container) {
    throw new Error('The root container was not found. Failed to mount react application');
}

const root = createRoot(container);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>

);


reportWebVitals();
