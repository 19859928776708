import React from 'react';
import { Helmet } from 'react-helmet'
import {classNames} from "src/shared/lib/classNames/classNames";
import cls from './MainPage.module.scss'
import {AppText} from "src/shared/ui/AppText";
import {AppTextTheme} from "src/shared/ui/AppText/ui/AppText";
import {Apps} from "src/widgets/Apps";
import {Link} from "react-router-dom";
import HeroImage from 'src/shared/assets/img/hero.png'
import LogoImage from 'src/shared/assets/img/logo.svg'

export const MainPage = () => {
    return (
        <section className={classNames(cls.mainPage, {}, [])}>
            <Helmet>
                <title>{'UMI Walk'}</title>
                <meta name="description" content={'Play the M2E and P2E game UMI Walk and get rewards for your activity. Improve your health and be happy and cheerful!'} />
                <meta name="keywords" content={'m2e, p2e token UMI, UMI walk'} />
            </Helmet>
            <img className={cls.mainPageHeroImage} src={HeroImage} alt="Hero image"/>
            <div className={cls.mainPageRodWalkContainer}>
                <div className={cls.mainPageTitleContainer}>
                    <img src={LogoImage} alt={'logo'} className={cls.logo} />
                    <AppText
                        text={'Be active, be healthy!'}
                        fontSize={18}
                        fontWeight={400}
                        lineHeight={26}
                    />
                </div>
                <Apps/>
                <div className={cls.mainPageLinks}>
                    <Link to={'/terms-of-use'}>
                        <AppText
                            text={'Terms, Rules and Privacy Policy of the Application'}
                            fontSize={18}
                            fontWeight={400}
                            lineHeight={26}
                            theme={AppTextTheme.BLUE}
                        />
                    </Link>
                    <Link to={'/support'}>
                        <AppText
                            text={'Contact us'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={17}
                            theme={AppTextTheme.GREY}
                        />
                    </Link>
                        <AppText
                            text={'Powered by UMI Products LLC in 2024'}
                            fontSize={12}
                            fontWeight={400}
                            lineHeight={17}
                            theme={AppTextTheme.GREY}
                        />
                </div>
            </div>
        </section>
    );
};
