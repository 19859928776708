import React from 'react';
import {classNames} from "src/shared/lib/classNames/classNames";
import cls from "./SupportPage.module.scss";
import {Helmet} from "react-helmet";
import {AppText} from "src/shared/ui/AppText";
import {Link} from "react-router-dom";
import {AppTextTheme} from "src/shared/ui/AppText/ui/AppText";
import LogoImage from "src/shared/assets/img/logo.svg";
import {Logo} from "src/widgets/Logo";

export const SupportPage = () => {
    return (
        <section className={classNames(cls.supportPage, {}, [])}>
            <Helmet>
                <title>{'UMI Walk support'}</title>
                <meta name="description" content={'Got a question about exchanging tokens, getting tokens, or other aspects of the move to earn game? We are always happy to help!'} />
                <meta name="keywords" content={'token exchange, get tokens, move to earn, umi token'} />
            </Helmet>
            <Logo height={'100px'} />
            <AppText
                className={cls.supportPageTitle}
                text={'Do you have anything to ask or share? Please, send your questions, suggestions and proposals to our e-mail:'}
                fontSize={40}
                fontWeight={700}
                lineHeight={46}
                align={"center"}
            />
            <AppText
                className={cls.supportPageEmail}
                text={'support@rodwalk.xyz'}
                fontSize={24}
                fontWeight={400}
                lineHeight={26}
                theme={AppTextTheme.BLUE}
                />
        </section>
    );
};

